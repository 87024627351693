<template>
  <v-app>
    <loading
      :active.sync="waitingForData"
      :is-full-page="true"
    />
    <!-- Standard Print Footer -->
    <div class="print-footer">
      <ReportFooter
        :organization="organization"
        :export-by="exportBy"
        :export-at="exportAt"
      />
    </div>
    <v-col
      class="align-self-center pa-3 text-right report-actions"
      style="top:0; right:0;"
    >
      <v-btn
        depressed
        text
        dark
        @click="onPrint"
      >
        <v-icon left>
          mdi-printer
        </v-icon>
        {{ $t('report.actions.print') + ' / ' + $t('report.actions.exportPDF') }}
        <v-icon right>
          mdi-file-pdf-box
        </v-icon>
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            depressed
            text
            dark
            data-cy="lang_btn"
          >
            <v-icon left>
              mdi-web
            </v-icon>{{ $i18n.locale == 'en' ? 'ENG' : '繁體' }}
          </v-btn>
        </template>
        <v-list
          data-cy="lang_list"
        >
          <v-list-item
            @click="loadLanguageAsync('en')"
            data-cy="eng_btn"
          >
            <v-list-item-title>ENG</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="loadLanguageAsync('zh-Hant')"
            data-cy="chn_btn"
          >
            <v-list-item-title>繁體</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <table>
      <thead>
        <tr>
          <td>
            <!--place holder for the fixed-position header-->
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <!--*** CONTENT GOES HERE ***-->
            <router-view
              :data="data"
              :organization="organization"
              :export-by="exportBy"
              :export-at="exportAt"
            />
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <!--place holder for the fixed-position footer-->
          </td>
        </tr>
      </tfoot>
    </table>
  </v-app>
</template>

<script>
import ReportFooter from '@/components/reports/common/ReportFooter'

export default {
  components: {
    ReportFooter
  },
  data () {
    return {
      data: null,
      organization: '',
      exportBy: '',
      exportAt: '',
      waitingForData: true
    }
  },
  methods: {
    setReportData (data, organization, exportBy) {
      this.data = data
      this.organization = organization
      this.exportBy = exportBy
      this.exportAt = this.moment().format('YYYY-MM-DD HH:mm:ss')
      this.waitingForData = false
      console.log(this.waitingForData)
    },
    onPrint () {
      window.print()
    }
  },
  mounted () {
    window.setReportData = this.setReportData
    this.waitingForData = true
    window.opener.childWindowReady()
  },
  beforeDestroy () {
    window.setReportData = null
  }
}
</script>

<style>
html {
  font-size: 12px !important;
}

table {
  width: 100%;
  border-collapse: collapse;
}

@page {
  size: A4;
  margin: 0;
  overflow: visible;
}

@media print {
  thead {display: table-header-group;}
  tfoot {display: table-footer-group;}

  /* button {display: none;} */

  body {margin: 0;}

  .v-application--wrap {
    background: none;
  }

  .report-actions {
    display: none;
  }

  .print-footer {
    display: block !important;
    position: fixed;
    width: 100%;
    height: 110px;
    z-index: 9999;
    bottom: 0;
    left: 0;
  }
}

.print-footer {
  width: 21cm;
  display: none;
}

</style>

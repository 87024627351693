<template>
  <div class="report-footer">
    <div class="footer-user-info">
      <div>
        <span class="font-weight-bold grey--text text--darken-1 footer-user-info--text">{{ $t('report.footer.organization') }}</span>
        {{ organization }}
      </div>
      <div>
        <span class="font-weight-bold grey--text text--darken-1  footer-user-info--text">
          {{ $t('report.footer.exportedBy') }}
        </span>
        {{ exportBy }}
      </div>
      <div>
        <span class="font-weight-bold grey--text text--darken-1 footer-user-info--text">
          {{ $t('report.footer.exportedAt') }}
        </span>
        {{ exportAt }}
      </div>
    </div>
    <!-- <span class="pageNum" /> -->
    <img
      src="@/assets/reports/Logo-TESS-ASIA.webp"
      class="footer-logo"
    >
    <span class="footer-copyright">{{ $t('footer.copyright') }}</span>
  </div>
</template>

<script>
export default {
  props: {
    organization: {
      type: String,
      default: ''
    },
    exportBy: {
      type: String,
      default: ''
    },
    exportAt: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

.footer-logo {
  position: absolute;
  bottom: 46px;
  right: 16px;
  width: 156px;
  user-select: none;
}

.footer-copyright {
  color: #363482;
  font-weight: 500;
  position: absolute;
  right: 16px;
  bottom: 23px;
  user-select: none;
}

.footer-user-info {
  position: absolute;
  bottom: 27px;
  font-size: 14px;
  left: 16px;
  line-height: 22px;
  letter-spacing: 0.6px;
}

.footer-user-info--text {
  display: inline-block;
  width: 114px;
}

/* .pageNum:after {
  counter-increment: page;
  content: counter(page);
} */

</style>
